<template>
  <div class="dev-group">
    <h2 class="group-title">Colors</h2>

    <h3 class="group-title">Base</h3>

    <b-row>
      <b-col v-for="(color, cKey, index) in scss.colors.base" :key="`color-${color}-${index}`" cols="6" sm="4" md="3" lg="2" class="mb-3 text-center">
        <b-card no-body :img-src="`//via.placeholder.com/500x250/${colorStripHash(color)}/${colorStripHash(colorGetContrast(color, scss.vars.colorContrastThreshold))}/?text=  %23${color6Digits(color)}   `" img-top/>
        <small class="mt-1 text-break form-text text-muted">{{ colorGetName(cKey) }} ({{ colorGetVariable(cKey) }})</small>
      </b-col>
    </b-row>

    <h3 class="group-title">Gray</h3>

    <b-row>
      <b-col v-for="(color, cKey, index) in scss.colors.gray" :key="`color-${color}-${index}`" cols="6" sm="4" md="3" lg="2" class="mb-3 text-center">
        <b-card no-body :img-src="`//via.placeholder.com/500x250/${colorStripHash(color)}/${colorStripHash(colorGetContrast(color, scss.vars.colorContrastThreshold))}/?text=  %23${color6Digits(color)}   `" img-top/>
        <small class="mt-1 text-break form-text text-muted">{{ colorGetName(cKey) }} ({{ colorGetVariable(cKey) }})</small>
      </b-col>
    </b-row>

    <h3 class="group-title">Theme</h3>

    <b-row>
      <b-col v-for="(color, cKey, index) in scss.colors.theme" :key="`color-${color}-${index}`" cols="6" sm="4" md="3" lg="2" class="mb-3 text-center">
        <b-card no-body :img-src="`//via.placeholder.com/500x250/${colorStripHash(color)}/${colorStripHash(colorGetContrast(color, scss.vars.colorContrastThreshold))}/?text=  %23${color6Digits(color)}   `" img-top/>
        <small class="mt-1 text-break form-text text-muted">{{ colorGetName(cKey) }} ({{ colorGetVariable(cKey) }})</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DevColors',
  data () {
    return {
      scss: this.$root.scss
    }
  },
  methods: {
    colorGetName (colorKey) {
      return `${colorKey.charAt(0).toUpperCase()}${colorKey.slice(1)}`
    },
    colorGetVariable (colorKey) {
      return `$${colorKey}`
    },
    color6Digits (hexColor) {
      if (hexColor.indexOf('#') === 0) hexColor = hexColor.slice(1)
      if (hexColor.length === 3) hexColor = `${hexColor[0]}${hexColor[0]}${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}`

      return hexColor
    },
    colorStripHash (hexColor) {
      return hexColor.replace('#', '')
    },
    colorGetContrast (hexColor, threshold = 180) {
      hexColor = this.color6Digits(hexColor)

      if (hexColor.length !== 6) {
        console.error(`${hexColor} is not a valid HEX Color!`)
        return 'initial'
      }

      // http://stackoverflow.com/a/3943023/112731
      const r = parseInt(hexColor.slice(0, 2), 16) * 0.299
      const g = parseInt(hexColor.slice(2, 4), 16) * 0.587
      const b = parseInt(hexColor.slice(4, 6), 16) * 0.114

      return r + g + b > threshold ? this.scss.base.black : this.scss.base.white
    }
  }
}
</script>
